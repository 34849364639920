import axios from "axios";
import {Message} from "element-ui";


axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 对应国际化资源文件后缀
axios.defaults.headers['Content-Language'] = 'zh_CN'

const service = axios.create({
  // baseURL: "http://192.168.0.112:8515",
  baseURL: "https://car.jxyouyuntech.com",
  timeout: 10000
});

const errorCode = {
  '401': '认证失败，无法访问系统资源',
  '403': '当前操作没有权限',
  '404': '访问资源不存在',
  'default': '系统未知错误，请反馈给管理员'
}

// request拦截器
service.interceptors.request.use(config => {
  /** 参数处理 */
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  return config
}, error => {
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
// 未设置状态码则默认成功状态
  const code = res.data.code || 200;
  // 获取错误信息
  const msg = errorCode[code] || res.data.msg || errorCode['default']
  // 二进制数据则直接返回
  if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
    return res.data
  }
  if (code === 401) {
    Message.warning("身份过期，请刷新页面后重试！");
    return Promise.reject('无效的会话，或者会话已过期，请重新登录。');
  } else if (code === 500) {
    Message({message: msg, type: 'error'})
    return Promise.reject(new Error(msg))
  } else if (code === 601) {
    Message({message: msg, type: 'warning'})
    return Promise.reject('error')
  } else if (code !== 200) {
    Notification.error({title: msg})
    return Promise.reject('error')
  } else {
    return res.data
  }
}, error => {
  let {message} = error;
  if (message == "Network Error") {
    message = "后端接口连接异常";
  } else if (message.includes("timeout")) {
    message = "系统接口请求超时";
  } else if (message.includes("Request failed with status code")) {
    message = "系统接口" + message.substr(message.length - 3) + "异常";
  }
  Message({message: message, type: 'error', duration: 5 * 1000})
  return Promise.reject(error)
})


/**
 * 参数处理
 * @param {*} params  参数
 */
export function tansParams(params) {
  let result = ''
  for (const propName of Object.keys(params)) {
    const value = params[propName];
    var part = encodeURIComponent(propName) + "=";
    if (value !== null && value !== "" && typeof (value) !== "undefined") {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && value[key] !== "" && typeof (value[key]) !== 'undefined') {
            let params = propName + '[' + key + ']';
            var subPart = encodeURIComponent(params) + "=";
            result += subPart + encodeURIComponent(value[key]) + "&";
          }
        }
      } else {
        result += part + encodeURIComponent(value) + "&";
      }
    }
  }
  return result
}

export default service
