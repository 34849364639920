<!--
*@carLifeDetail
*@author Z7M
*@date 2024/1/23 9:26
-->
<template>
  <div>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="60%"
        :before-close="handleClose">
      <h2 style="text-align: center">
        {{ carLifeData.title }}
      </h2>
      <div>
        <el-image :src="image(carLifeData.cover)" style="width: 100%;height: 300px"/>
      </div>
      <div>
        {{carLifeData.content}}
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {baseUrl} from "@/api/website";

export default {
  name: "carLifeDetail",
  props: {},
  components: {},
  data() {
    return {
      dialogVisible: false,
      carLifeData: {},
    }
  },
  methods: {
    image(url) {
      return baseUrl + url
    },
    handleOpen(data) {
      this.carLifeData = data;
      console.log(data);
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    }
  },
  mounted() {
  },
}
</script>

<style scoped lang="scss">
:deep(.el-dialog__header) {
  display: none;
}
:deep(.el-dialog__body) {
  background: #fff !important;
}
</style>
