import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/homeView.vue'
import aboutMe from "@/views/aboutMe.vue";
import showProduct from "@/views/showProduct.vue";
import carLife from "@/views/carLife.vue";
import contactMe from "@/views/contactMe.vue";

Vue.use(VueRouter)

// 解决重复点击导航路由报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err)
};

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/aboutMe',
    name: 'aboutMe',
    component: aboutMe
  },
  {
    path: '/showProduct',
    name: 'showProduct',
    component: showProduct
  },
  {
    path: '/carLife',
    name: 'carLife',
    component: carLife
  },
  {
    path: '/contactMe',
    name: 'contactMe',
    component: contactMe
  },
]

const router = new VueRouter({
  routes
})

export default router
