var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_vm._m(0),_c('div',{staticClass:"back2"},[_c('div',{ref:"back2",staticClass:"content"},[_c('div',{staticClass:"mask"}),_vm._m(1)])]),_c('div',{staticClass:"back3"},[_c('div',{ref:"back3",staticClass:"content"},[_c('div',{staticClass:"mask"}),_vm._m(2)])]),_c('div',{staticClass:"back4"},[_c('div',{ref:"back4",staticClass:"content"},[_c('div',{staticClass:"mask"}),_vm._m(3)])]),_c('div',{staticClass:"back5"},[_c('div',{ref:"box5",staticClass:"box"},[_c('div',{staticClass:"box1"},[_c('el-rate',{attrs:{"disabled":"","text-color":"#ff9900","score-template":"{value}"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),_vm._m(4),_c('div',{staticClass:"box3"},[_vm._v(" 我们的五星客服团队是一个专业、友善、高效的团队，致力于为客户提供最优质的服务体验。 ")]),_vm._m(5)])]),_c('div',{staticStyle:{"height":"100px"}}),_c('div',{staticStyle:{"height":"100px"}}),_c('home-footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"back1"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"headline"},[_vm._v(" 一家专注于二手车"),_c('br'),_vm._v("交易的在线平台 ")]),_c('div',{staticClass:"subheading"},[_vm._v(" A company focused on used cars"),_c('br'),_vm._v(" Online platform for transactions ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"animation"},[_c('div',{staticClass:"title1"},[_vm._v("用专业的心"),_c('br'),_vm._v("做专业的事")]),_c('div',{staticClass:"title2"},[_vm._v(" 我们的专业团队由经验丰富的技术人员组成，他们具备丰富的行业经验和卓越的服务意识。我们采用高品质的零部件和油品，确保车辆的性能和安全性。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"animation"},[_c('div',{staticClass:"title1"},[_vm._v("专业保养"),_c('br'),_vm._v("行车更安全")]),_c('div',{staticClass:"title2"},[_vm._v(" 我们的汽车保养团队是一支经验丰富、技术精湛的专业团队，致力于为客户提供高质量、全面的汽车保养服务。我们的团队由资深的技术人员组成，他们具备丰富的行业经验和卓越的服务意识，能够解决各种汽车保养问题，并确保车辆的性能和安全性。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"animation"},[_c('div',{staticClass:"title1"},[_vm._v("诚信经营"),_c('br'),_vm._v("专业评估"),_c('br'),_vm._v("让您的购车更放心")]),_c('div',{staticClass:"title2"},[_vm._v(" 选择我们的平台，您将能够轻松找到心仪的二手车，并享受到安全、便捷、高效的交易服务。如果您有任何问题或需要帮助，请随时联系我们的客服团队。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box2"},[_c('div',{staticClass:"box2_children"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box4"},[_c('div',{staticClass:"box4_children"})])
}]

export { render, staticRenderFns }