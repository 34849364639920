<template>
  <div class="home">
    <div class="back1">
      <div class="content">
        <div class="headline">
          一家专注于二手车<br/>交易的在线平台
        </div>
        <div class="subheading">
          A company focused on used cars<br/>
          Online platform for transactions
        </div>
      </div>
    </div>
    <div class="back2">
      <div ref="back2" class="content">
        <div class="mask"/>
        <div class="animation">
          <div class="title1">用专业的心<br/>做专业的事</div>
          <div class="title2">
            我们的专业团队由经验丰富的技术人员组成，他们具备丰富的行业经验和卓越的服务意识。我们采用高品质的零部件和油品，确保车辆的性能和安全性。
          </div>
        </div>
      </div>
    </div>
    <div class="back3">
      <div ref="back3" class="content">
        <div class="mask"/>
        <div class="animation">
          <div class="title1">专业保养<br/>行车更安全</div>
          <div class="title2">
            我们的汽车保养团队是一支经验丰富、技术精湛的专业团队，致力于为客户提供高质量、全面的汽车保养服务。我们的团队由资深的技术人员组成，他们具备丰富的行业经验和卓越的服务意识，能够解决各种汽车保养问题，并确保车辆的性能和安全性。
          </div>
        </div>
      </div>
    </div>
    <div class="back4">
      <div ref="back4" class="content">
        <div class="mask"/>
        <div class="animation">
          <div class="title1">诚信经营<br/>专业评估<br/>让您的购车更放心</div>
          <div class="title2">
            选择我们的平台，您将能够轻松找到心仪的二手车，并享受到安全、便捷、高效的交易服务。如果您有任何问题或需要帮助，请随时联系我们的客服团队。
          </div>
        </div>
      </div>
    </div>
    <div class="back5">
      <div class="box" ref="box5">
        <div class="box1">
          <el-rate
              v-model="value"
              disabled
              text-color="#ff9900"
              score-template="{value}">
          </el-rate>
        </div>
        <div class="box2">
          <div class="box2_children"/>
        </div>
        <div class="box3">
          我们的五星客服团队是一个专业、友善、高效的团队，致力于为客户提供最优质的服务体验。
        </div>
        <div class="box4">
          <div class="box4_children"/>
        </div>
      </div>
    </div>
    <div style="height: 100px"/>
    <div style="height: 100px"/>
    <home-footer/>
  </div>
</template>

<script>
import HomeFooter from "@/components/homeFooter.vue";

export default {
  name: 'HomeView',
  components: {HomeFooter},
  data() {
    return {
      value: 5
    }
  },
  methods: {},
  mounted() {
    const back2 = new IntersectionObserver((entries) => {
      for (const key of entries) {
        if (key.isIntersecting) {
          console.log(key.target);
          key.target.classList.add("back_move_right");
        }
      }
    }, {root: null, threshold: .3})
    back2.observe(this.$refs.back2)

    const back3 = new IntersectionObserver((entries) => {
      for (const key of entries) {
        if (key.isIntersecting) {
          console.log(key.target);
          key.target.classList.add("back_move_left");
        }
      }
    }, {root: null, threshold: .3})
    back3.observe(this.$refs.back3)

    const back4 = new IntersectionObserver((entries) => {
      for (const key of entries) {
        if (key.isIntersecting) {
          console.log(key.target);
          key.target.classList.add("back_move_right");
        }
      }
    }, {root: null, threshold: .3})
    back4.observe(this.$refs.back4)

    const back5 = new IntersectionObserver((entries) => {
      for (const key of entries) {
        if (key.isIntersecting) {
          console.log(key.target);
          key.target.classList.add("back_move_top");
        }
      }
    }, {root: null, threshold: .3})
    back5.observe(this.$refs.box5)
  }
}
</script>
<style scoped lang="scss">
.home {
  min-height: 100vh;

  .back1 {
    height: 1000px;
    background-image: url("@/assets/back1.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;

    .content {
      width: 1200px;
      height: 100%;
      display: flex;
      flex-direction: column;

      .headline {
        padding-top: 170px;
        font-size: 46px;
        color: #ffffff;
        font-weight: bold;
        font-family: "微软雅黑";
        font-style: oblique;
      }

      .subheading {
        padding-top: 80px;
        font-size: 22px;
        color: #ffffff;
        font-family: "微软雅黑";
      }
    }
  }

  .back2 {
    height: 770px;
    background-image: url("@/assets/back2.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow-x: hidden;

    .content {
      width: 100px;
      height: 300px;
      position: relative;

      .mask {
        position: absolute;
        z-index: 2;
        width: 100px;
        height: 300px;
        background: rgb(17, 14, 17);
      }


      .animation {
        position: absolute;
        width: 270px;
        height: 300px;
        top: 0;
        right: -350px;
        color: #ffffff;

        .title1 {
          font-size: 46px;
          line-height: 58px;
        }

        .title2 {
          font-size: 14px;
          padding-top: 20px;
          line-height: 30px;
        }
      }
    }

    .back_move_right {
      .animation {
        z-index: 1;
        animation: move_right .7s ease forwards;
      }
    }
  }

  .back3 {
    height: 770px;
    background-image: url("@/assets/back3.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;

    .content {
      width: 100px;
      height: 300px;
      position: relative;

      .mask {
        position: absolute;
        z-index: 2;
        width: 100px;
        height: 300px;
        background: #fff;
      }


      .animation {
        position: absolute;
        width: 350px;
        height: 300px;
        top: 0;
        right: -350px;
        color: #000;

        .title1 {
          font-size: 36px;
          line-height: 58px;
        }

        .title2 {
          font-size: 14px;
          padding-top: 20px;
          line-height: 30px;
        }
      }
    }

    .back_move_left {
      .animation {
        z-index: 1;
        animation: move_left .7s ease forwards;
      }
    }
  }

  .back4 {
    height: 770px;
    background-image: url("@/assets/back4.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow-x: hidden;

    .content {
      width: 100px;
      height: 300px;
      position: relative;

      .mask {
        position: absolute;
        z-index: 2;
        width: 100px;
        height: 300px;
        background: rgb(17, 14, 17);
      }


      .animation {
        position: absolute;
        width: 300px;
        height: 300px;
        top: 0;
        right: -350px;
        color: #ffffff;

        .title1 {
          font-size: 36px;
          line-height: 58px;
        }

        .title2 {
          font-size: 14px;
          padding-top: 20px;
          line-height: 30px;
        }
      }
    }

    .back_move_right {
      .animation {
        z-index: 1;
        animation: move_right .7s ease forwards;
      }
    }
  }

  .back5 {
    height: 740px;
    background-image: url("@/assets/back5.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .box {

      position: relative;

      .box1 {

        :deep(.el-rate__icon) {
          font-size: 36px;
        }

        height: 36px;
        width: 100%;
        text-align: center;
        position: absolute;
        z-index: 1;
        bottom: 360px;
      }

      .box2 {
        height: 36px;
        width: 100%;
        position: relative;
        margin-top: 36px;

        .box2_children {
          width: 100%;
          height: 100%;
          position: absolute;
          background: #fff;
          top: 0;
          right: 0;
          z-index: 2;
        }
      }

      .box3 {
        width: 510px;
        height: 180px;
        text-align: center;
        font-size: 30px;
        line-height: 60px;
        position: absolute;
        z-index: 1;
        bottom: 0;
      }

      .box4 {
        width: 510px;
        height: 180px;
        margin-top: 180px;
        position: relative;

        .box4_children {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
          background: #fff;
          z-index: 2;
        }
      }
    }

    .back_move_top {
      .box1 {
        animation: small_move_top .7s ease forwards;
      }

      .box3 {
        animation: big_move_top .7s ease forwards;
      }
    }
  }

}

@keyframes move_right {
  0% {
    right: -350px;
  }
  100% {
    right: 200px;
  }
}

@keyframes move_left {
  0% {
    left: -350px;
  }
  100% {
    left: 150px;
  }
}

@keyframes big_move_top {
  0% {
    bottom: 0;
  }
  100% {
    bottom: 180px;
  }
}

@keyframes small_move_top {
  0% {
    bottom: 360px;
  }
  100% {
    bottom: 396px;
  }
}
</style>
