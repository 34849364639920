import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    checkedTabs: "首页",
  },
  getters: {},
  mutations: {
    setCheckedTabs(state, payload) {
      state.checkedTabs = payload.checkedTabs
    }
  },
  actions: {},
  modules: {},
  /*将vuex存放再session中*/
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })]
})
