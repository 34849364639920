<!--
*@contactMe
*@author Z7M
*@date 2023/12/15 17:42
-->
<template>
  <div>
    <div class="contactMe"></div>
    <div class="contact_content" ref="contact_content">
      <div class="header">
        <div class="chinese">联系我们</div>
        <div class="english">CONTACT US</div>
      </div>
      <div class="content">
        <div v-for="(item,i) in data" :key="i" class="item">
          <div>
            <el-image :src="image(item.imgValue)" style="width: 70px;height: 70px"/>
          </div>
          <div style="width: 100%;height: 1px;background: #ddd;margin: 15px 0"/>
          <div style="text-align: center;height: 60px">
            <div>
              {{ item.keyName.split("-")[1] }}
            </div>
            <div style="width: 160px">
              {{ item.textValue }}
            </div>
          </div>
        </div>
      </div>
      <div class="address" id="map">

      </div>
    </div>
    <home-footer/>
  </div>
</template>

<script>
import HomeFooter from "@/components/homeFooter.vue";
import {getMeInfo, baseUrl} from "@/api/website";

export default {
  name: "contactMe",
  props: {},
  components: {HomeFooter},
  data() {
    return {
      data: [],
    }
  },
  methods: {
    getContactMe() {
      getMeInfo().then(data => {
        this.data = data.data;
      }).catch(error => {
        console.log(error);
      });
    },
    image(url) {
      return baseUrl + url
    },
    initMap() {
      // 定义地图中心点坐标115.926276,28.6616
      const center = new window.TMap.LatLng(28.6616, 115.926276);
      // 定义map变量，调用 TMap.Map() 构造函数创建地图
      const map = new window.TMap.Map(document.getElementById('map'), {
        center: center, // 设置地图中心点坐标
        zoom: 15, // 设置地图缩放级别
        pitch: 20, // 设置俯仰角
        rotation: 0 // 设置地图旋转角度
      });
      new window.TMap.MultiMarker({
        map: map,
        //点标记数据数组
        geometries: [
          {
            id: '1', //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            styleId: 'myStyle', //指定样式id
            position: new window.TMap.LatLng(28.6616, 115.926276), //点标记坐标位置
            properties: {
              //自定义属性
              title: '南昌'
            }
          }
        ]
      });
    }
  },
  mounted() {
    this.getContactMe();
    const contact_content = new IntersectionObserver((entries) => {
      for (const key of entries) {
        if (key.isIntersecting) {
          key.target.classList.add("move_top");
        }
      }
    }, {root: null, threshold: .3})
    contact_content.observe(this.$refs.contact_content)
    this.$nextTick(() => {
      this.initMap();
    });
  },
}
</script>

<style scoped lang="scss">
.contactMe {
  height: 500px;
  background-image: url("@/assets/contactMe.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.contact_content {
  height: 1000px;
  position: relative;

  .header {
    text-align: center;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    opacity: 0;

    .chinese {
      font-size: 30px;
      color: #000000;
      font-weight: bold;
    }

    .english {
      padding-top: 10px;
      font-size: 18px;
      color: #666666;
    }
  }

  .content {
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    width: 1200px;
    display: flex;
    justify-content: space-around;
    opacity: 0;

    .item {
      width: 215px;
      height: 215px;
      border-radius: 50%;
      border: 1px #ddd solid;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;
      box-sizing: border-box;
    }
  }

  .address {
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    width: 1200px;
    height: 300px;
    border: 1px #ddd solid;
    opacity: 0;
  }
}

.move_top {
  .header {
    animation: move_top 1s ease forwards;
  }

  .content {
    animation: content_move_top 1s ease forwards;
  }


  .address {
    animation: address_move_top 1s ease forwards;
  }
}

@keyframes move_top {
  0% {
    bottom: 0;
    opacity: 0;
  }
  100% {
    opacity: 1;
    bottom: 840px;
  }
}

@keyframes content_move_top {
  0% {
    bottom: 0;
    opacity: 0;
  }
  100% {
    opacity: 1;
    bottom: 540px;
  }
}

@keyframes address_move_top {
  0% {
    bottom: 0;
    opacity: 0.1;
  }
  100% {
    opacity: 1;
    bottom: 150px;
  }
}
</style>
