<!--
*@showProduct
*@author Z7M
*@date 2023/12/15 17:29
-->
<template>
  <div>
    <div class="product1"></div>
    <div style="height: 200px"/>
    <div class="show_product">
      <div class="title">产品展示</div>
      <div class="description">
        在追求产品不断进步的同时，红旗始终致力于为用户提供高品质的服务体验。以“行理想，心服务”为口号，以“专业、安心、尊享”为理念，立志为用户创造全方位的优质汽车生活。
      </div>
      <div class="content">
        <el-row :gutter="30">
          <el-col :span="6" v-for="(item,i) in data" :key="i">
            <div class="box">
              <el-image :src="image(item.picture)" style="width: 100%;height: 195px"/>
              <div class="des">
                <div>{{ item.brandType }}{{ new Date(item.yearBuilt).getFullYear() }}款</div>
                <div style="color: rgb(153, 153, 153);font-size: 14px">
                  <div>
                    {{
                      new Date(item.registeredDate).getFullYear()
                    }}-{{
                      new Date(item.registeredDate).getMonth() > 9 ? new Date(item.registeredDate).getMonth() : `0${new Date(item.registeredDate).getMonth()}`
                    }}上牌
                  </div>
                  <div style="padding-top: 10px">
                    行驶{{ item.mileage }}万公里
                  </div>
                </div>
                <div style="display: flex;justify-content: space-between;align-items: center;width: 100%">
                  <div style="color: #ff6637">
                    ￥<span style="font-size: 24px">{{ (item.price * 10000).toFixed(2) }}</span>
                  </div>
                  <div class="button" @click="lookInfo(item)">了解详情</div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="pagination">
          <el-pagination background layout="prev, pager, next"
                         @current-change="currentChange"
                         :total="total"/>
        </div>
      </div>
    </div>
    <home-footer/>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="60%"
        :before-close="handleClose">
      <div class="info">
        <div class="carousel">
          <el-carousel height="450px">
            <el-carousel-item v-for="item in imageArr" :key="item">
              <el-image :src="image(item)" style="width: 100%;height: 450px"/>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="detail">
          <div class="title">分类：</div>
          <el-row :gutter="10" class="category">
            <el-col :span="12">
              <div class="box" :class="{'check_box':isCheck===1}" @click="switchImg(1)">
                <div style="font-size: 18px;font-weight: bold">外观</div>
                <div style="font-size: 12px">({{ carInfo.picture ? carInfo.picture.split(",").length : 0 }}张)</div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="box" :class="{'check_box':isCheck===2}" @click="switchImg(2)">
                <div style="font-size: 18px;font-weight: bold">内饰</div>
                <div style="font-size: 12px">({{ carInfo.interior ? carInfo.interior.split(",").length : 0 }}张)</div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="box" :class="{'check_box':isCheck===3}" @click="switchImg(3)">
                <div style="font-size: 18px;font-weight: bold">细节</div>
                <div style="font-size: 12px">({{ carInfo.detail ? carInfo.detail.split(",").length : 0 }}张)</div>
              </div>
            </el-col>
          </el-row>
          <div class="car_name">
            {{ carInfo.brandType }}{{ new Date(carInfo.yearBuilt).getFullYear() }}款
          </div>
          <div style="color: #fff;font-size: 16px;margin-top: 20px">
            {{ new Date(carInfo.registeredDate).getFullYear() }} / {{ carInfo.mileage }}万公里
          </div>
          <div class="footer">
            <div style="font-size: 16px;font-weight: bold;color: #fff">二手车参考价</div>
            <div class="price">
              <span style="font-size: 16px;font-weight: bold">{{ (carInfo.price * 10000).toFixed(2) }}</span>
              <span style="font-size: 12px;padding-left: 5px;font-weight: bold">元</span>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import HomeFooter from "@/components/homeFooter.vue";
import {getVehicleData, getCarInfo, baseUrl} from "@/api/website";

export default {
  name: "showProduct",
  props: {},
  components: {HomeFooter},
  data() {
    return {
      pageNum: 1,
      total: 0,
      data: [],
      dialogVisible: false,
      isCheck: 1,
      carInfo: {
        picture: "",
        interior: "",
        detail: ""
      }
    }
  },
  computed: {
    imageArr() {
      if (this.isCheck === 1) {
        return this.carInfo.picture ? this.carInfo.picture.split(",") : []
      }
      if (this.isCheck === 2) {
        return this.carInfo.interior ? this.carInfo.interior.split(",") : []
      }
      if (this.isCheck === 3) {
        return this.carInfo.detail ? this.carInfo.detail.split(",") : []
      }

      return []
    }
  },
  methods: {
    getProductData() {
      getVehicleData({pageNum: this.pageNum, pageSize: 8}).then(data => {
        this.data = data.rows
        this.total = data.total
      }).catch(error => {
        console.log(error);
      })
    },
    image(url) {
      return baseUrl + url.split(",")[0]
    },
    currentChange(current) {
      this.pageNum = current
      this.getProductData()
    },
    lookInfo(car) {
      this.dialogVisible = true
      this.isCheck = 1
      getCarInfo(car.id).then(data => {
        this.carInfo = data.data
      }).catch(error => {
        console.log(error);
      })
    },
    handleClose() {
      this.dialogVisible = false
    },
    switchImg(type) {
      this.isCheck = type
    },
  },
  mounted() {
    this.getProductData()
  },
}
</script>

<style scoped lang="scss">
.product1 {
  height: 500px;
  background-image: url("@/assets/product1.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.show_product {
  width: 1200px;
  margin: 0 auto;
  min-height: 200px;

  .title {
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    color: #444;
  }

  .description {
    width: 910px;
    margin: 0 auto;
    text-align: center;
    padding: 60px 0;
    color: rgb(155, 156, 156);
    line-height: 28px;
  }

  .content {
    width: 1200px;
    min-height: 400px;

    .box {
      width: 100%;
      height: 350px;
      position: relative;


      .des {
        height: 160px;
        width: calc(100% - 20px);
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
        position: absolute;
        left: 10px;
        bottom: 20px;
        z-index: 2;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        border-radius: 6px;
        padding: 0 10px;
        box-sizing: border-box;

        .button {
          width: 100px;
          background: #ff6637;
          height: 30px;
          color: #fff;
          text-align: center;
          line-height: 30px;
          border-radius: 6px;
          cursor: pointer;
        }
      }
    }

    .pagination {
      display: flex;
      justify-content: center;
      padding: 60px 0;

      :deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
        background-color: #ff6637;
      }
    }
  }
}

:deep(.el-dialog__header) {
  display: none;
}

:deep(.el-dialog__body) {
  background: #3F3939;
}

.info {
  display: flex;

  .carousel {
    flex: 1;
    height: 450px;
  }

  .detail {
    width: 250px;
    margin: 0 20px;
    height: 450px;
    position: relative;

    .title {
      color: #ffffff;
      font-size: 16px;
    }

    .category {
      padding-top: 10px;

      .box {
        background: #666;
        width: 100%;
        height: 80px;
        margin-bottom: 10px;
        text-align: center;
        color: #ffffff;
        padding-top: 20px;
        box-sizing: border-box;
        cursor: pointer;
      }

      .check_box {
        background: #409EFF;
      }
    }

    .car_name {
      font-size: 18px;
      color: #ffffff;
      font-weight: bold;
      margin-top: 30px;
    }

    .footer {
      position: absolute;
      bottom: 0;
      left: 0;

      .price {
        width: 250px;
        background: #666;
        height: 40px;
        margin-top: 10px;
        line-height: 40px;
        padding-left: 10px;
        box-sizing: border-box;
        color: #409EFF;
      }
    }
  }
}
</style>
