<!--
*@carLife
*@author Z7M
*@date 2023/12/15 17:41
-->
<template>
  <div>
    <div class="back1">
      <div class="content">
        <div class="headline">
          一家专注于二手车<br/>交易的在线平台
        </div>
        <div class="subheading">
          A company focused on used cars<br/>
          Online platform for transactions
        </div>
      </div>
    </div>
    <div class="car_life">
      <div class="content">
        <el-row :gutter="10">
          <el-col :span="6" v-for="item in lifeArr" :key="item.id">
            <div class="box" @click="lookInfo(item)">
              <div class="tag">
                {{ item.label }}
              </div>
              <el-image class="image" :src="image(item.cover)"/>
              <div class="des">
                <div>{{ item.title }}</div>
                <div style="font-size: 12px;color: #909399">{{ item.createTime.slice(0, 10) }}</div>
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="pagination">
          <el-pagination background layout="prev, pager, next"
                         @current-change="currentChange"
                         :total="total"/>
        </div>
      </div>
    </div>
    <home-footer/>
    <car-life-detail ref="carLifeDetail"/>
  </div>
</template>

<script>
import HomeFooter from "@/components/homeFooter.vue";
import {getBikerLife, baseUrl} from "@/api/website";
import CarLifeDetail from "@/components/carLifeDetail.vue";

export default {
  name: "carLife",
  props: {},
  components: {CarLifeDetail, HomeFooter},
  data() {
    return {
      lifeArr: [],
      pageNum: 1,
      total: 0,
    }
  },
  methods: {
    getData() {
      getBikerLife({pageNum: this.pageNum, pageSize: 8}).then(data => {
        this.lifeArr = data.rows;
        this.total = data.total
      }).catch(error => {
        console.log(error);
      })
    },
    currentChange(current) {
      this.pageNum = current
      this.getData()
    },
    image(url) {
      return baseUrl + url
    },
    lookInfo(item) {
      this.$refs.carLifeDetail.handleOpen(item);
    },
  },
  mounted() {
    this.getData()
  },
}
</script>

<style scoped lang="scss">
.back1 {
  height: 1000px;
  background-image: url("@/assets/back1.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;

  .content {
    width: 1200px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .headline {
      padding-top: 170px;
      font-size: 46px;
      color: #ffffff;
      font-weight: bold;
      font-family: "微软雅黑";
      font-style: oblique;
    }

    .subheading {
      padding-top: 80px;
      font-size: 22px;
      color: #ffffff;
      font-family: "微软雅黑";
    }
  }
}

.car_life {
  padding: 60px 0;
  background: #fff;

  .content {
    width: 1200px;
    margin: 0 auto;

    .box {
      cursor: pointer;
      width: 100%;
      height: 300px;
      background: #f5f5f5;
      border-radius: 12px;
      margin-top: 10px;
      position: relative;

      .tag {
        position: absolute;
        top: 10px;
        right: 10px;
        border-radius: 6px;
        background: #444C55;
        z-index: 2;
        text-align: center;
        font-size: 14px;
        color: #ffffff;
        padding: 4px 16px;
      }

      .image {
        width: 100%;
        height: 150px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }

      .des {
        height: 150px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 0 16px;
        box-sizing: border-box;
      }
    }

    .pagination {
      display: flex;
      justify-content: center;
      padding: 60px 0;

      :deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
        background-color: #ff6637;
      }
    }
  }
}
</style>
