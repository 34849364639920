<!--
*@homeFooter
*@author Z7M
*@date 2023/12/15 17:18
-->
<template>
  <div>
    <div class="footer">
      <div class="vue_qr">
        <div>
          <vue-qr text="抖音小程序" size="70" margin="1"/>
          <div>
            抖音小程序
          </div>
        </div>
        <div style="padding-left: 15px">
          <vue-qr text="微信小程序" size="70" margin="1"/>
          <div>
            微信小程序
          </div>
        </div>
      </div>
      <div class="middle">
        <el-image :src="fotter"/>
        <div class="title">
          Second-hand Cars
        </div>
        <div class="ban_quan">
          ©2024 优云科技 版权所有
        </div>
      </div>
      <div class="contact_me">
        <div class="value" v-for="item in data" :key="item.id">
          <div>
            {{ item.keyName.split("-")[1] }}：
          </div>
          <div>
            {{ item.textValue }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fotter from "@/assets/fotter.webp";
import vueQr from "vue-qr";
import {getMeInfo, baseUrl} from "@/api/website";

export default {
  name: "homeFooter",
  props: {},
  components: {
    vueQr,
  },
  data() {
    return {
      fotter: fotter,
      data: [],
    }
  },
  methods: {
    getContactMe() {
      getMeInfo().then(data => {
        this.data = data.data;
      }).catch(error => {
        console.log(error);
      });
    },
    image(url) {
      return baseUrl + url
    },
  },
  mounted() {
    this.getContactMe();
  },
}
</script>

<style scoped lang="scss">

.footer {
  height: 200px;
  background: rgb(83, 83, 83);
  display: flex;
  justify-content: space-around;
  align-items: center;

  .vue_qr {
    width: 300px;
    display: flex;
    text-align: center;
    font-size: 12px;
    color: #ccc;
  }

  .middle {
    font-size: 20px;
    color: #ccc;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .ban_quan {
      font-size: 16px;
      padding-top: 5px;
      color: #777;
      bottom: 1px;
    }

    .title {
      padding-top: 15px;
    }
  }

  .contact_me {
    width: 300px;

    .value {
      display: flex;
      color: #ccc;

      div:nth-of-type(1) {
        height: 30px;
        line-height: 30px;
        font-size: 12px;
      }

      div:nth-of-type(2) {
        width: 220px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
      }
    }
  }
}
</style>
