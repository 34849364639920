<template>
  <div id="app" class="page">
    <home-header class="home_header"/>
    <router-view/>
  </div>
</template>
<script>
import HomeHeader from "@/components/homeHeader.vue";

export default {
  components: {HomeHeader},
  methods: {}
};
</script>

<style scoped lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page {
  position: relative;

  .home_header {
    position: absolute;
    top: 30px;
    z-index: 2;
  }
}
</style>
<style>
html body {
  margin: 0;
  padding: 0;
}
</style>
