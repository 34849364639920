<!--
*@homeHeader
*@author Z7M
*@date 2023/12/14 13:49
-->
<template>
  <div class="home_header">
    <div class="header">
      <div class="title">新起点二手车</div>
      <div class="tabs">
        <div class="item" :class="{'check_item':item.name===checkedTabs}" v-for="(item,i) in tabs" :key="i"
             @click="saveTabs(item)">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from "vuex";

export default {
  name: "homeHeader",
  props: {},
  components: {},
  data() {
    return {
      tabs: [
        {name: "首页", router: "/"},
        {name: "关于我们", router: "/aboutMe"},
        {name: "产品展示", router: "/showProduct"},
        {name: "车主生活", router: "/carLife"},
        {name: "联系我们", router: "/contactMe"},
      ]
    }
  },
  computed: {
    ...mapState(['checkedTabs'])
  },
  methods: {
    ...mapMutations(['setCheckedTabs']),
    /**
     * 点击标签页事件
     * @param tabs
     */
    saveTabs(tabs) {
      this.setCheckedTabs({checkedTabs: tabs.name})
      this.$router.push({
        path: tabs.router
      })
    }
  },
  watch: {
    $route(to) {
      console.log(to)
      switch (to.path) {
        case '/':
          this.setCheckedTabs({checkedTabs: '首页'})
          break;
        case '/aboutMe':
          this.setCheckedTabs({checkedTabs: '关于我们'})
          break;
        case '/showProduct':
          this.setCheckedTabs({checkedTabs: '产品展示'})
          break;
        case '/carLife':
          this.setCheckedTabs({checkedTabs: '车主生活'})
          break;
        case '/contactMe':
          this.setCheckedTabs({checkedTabs: '联系我们'})
          break;
      }
    }
  },
  mounted() {
  },
}
</script>

<style scoped lang="scss">
.home_header {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;

  .header {
    width: 1200px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      color: #fff;
      font-weight: bold;
      font-size: 30px;
    }

    .tabs {
      display: flex;

      .item {
        padding-right: 40px;
        color: #fff;
        cursor: pointer;
      }

      .check_item {
        color: #ffc001;
      }
    }
  }
}
</style>
