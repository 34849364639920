<!--
*@aboutMe
*@author Z7M
*@date 2023/12/15 15:25
-->
<template>
  <div>
    <div class="aboutMe1"></div>
    <div class="team" ref="team">
      <el-image class="img" :src="teamInfo.background"/>
      <div class="description">
        <div class="title">{{ teamInfo.title }}</div>
        <div class="eTitle">{{ teamInfo.eTitle }}</div>
        <div class="line"/>
        <div class="content">{{ teamInfo.description }}</div>
      </div>
    </div>
    <div class="aboutMe2" ref="aboutMe2">
      <div v-for="(value,key) in count" :key="key" class="count">
        <div class="pos">
          <div class="num">
            {{ value.count }}
          </div>
          <div class="name">
            {{ value.name }}
          </div>
        </div>
        <div v-if="['vehiclesNum','bikerLife'].includes(key)" class="more" @click="lookMore(key)">
          <i :class="nowClickKey===key?`el-icon-caret-top`:`el-icon-caret-bottom`"/>点击展开
        </div>
      </div>
    </div>
    <el-collapse-transition>
      <div v-show="show">
        <div class="collapse">
          <el-row :gutter="20">
            <template v-if="nowClickKey==='vehiclesNum'">
              <el-col :span="8" v-for="(item,i) in carData" :key="i">
                <div class="box" @click="lookInfo(item)">
                  <div>
                    <el-image :src="returnImage(item.picture)" style="border-radius: 10px;width: 210px;height: 100px"/>
                  </div>
                  <div class="introduce">
                    <div>{{ item.brandType }}{{ new Date(item.yearBuilt).getFullYear() }}款</div>
                    <div style="color: #666;font-size: 12px">{{
                        new Date(item.registeredDate).getFullYear()
                      }}/{{ item.mileage }}w公里
                    </div>
                    <div style="font-size: 12px">
                      车价：<span style="font-size: 20px;color: #ffc001">{{ item.price }}</span> 万
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :span="24">
                <el-button size="medium"
                           type="warning"
                           style="width: 200px;height: 40px;border-radius: 10px;float: right;margin-top: 20px"
                           @click="jump('/showProduct')">更多车型
                </el-button>
              </el-col>
            </template>
            <template v-if="nowClickKey==='bikerLife'">
              <el-col :span="6" v-for="(item,i) in lifeData" :key="i">
                <div class="bikerLife_box" @click="lookInfoByLife(item)">
                  <div class="tag">
                    {{ item.label }}
                  </div>
                  <el-image class="image" :src="returnImage(item.cover)"/>
                  <div class="des">
                    <div>{{ item.title }}</div>
                    <div style="font-size: 12px;color: #909399">{{ item.createTime.slice(0, 10) }}</div>
                  </div>
                </div>
              </el-col>
              <el-col :span="24">
                <el-button size="medium"
                           type="warning"
                           style="width: 200px;height: 40px;border-radius: 10px;float: right;margin-top: 20px"
                           @click="jump('/carLife')">更多生活
                </el-button>
              </el-col>
            </template>
          </el-row>
        </div>
      </div>
    </el-collapse-transition>
    <div class="aboutMe3" ref="aboutMe3">
      <div class="box">
        <div class="header">
          <div class="title">
            我们的优势
            <div style="width: 40px;height: 4px;background: #dadada;margin-top: 20px"></div>
          </div>
        </div>
        <div class="advantage" ref="advantage">
          <div class="advantage_image">
            <el-image :src="advantage.background" style="width: 100%;height: 100%"></el-image>
          </div>
          <div class="advantage_content">
            <div v-for="(item,i) in advantage.list" :key="i" class="list">
              <div class="sub">
                0{{ i + 1 }}
              </div>
              <div style="width: 1px;height: 80px;background: #666;margin:0 20px"></div>
              <div>
                <div class="key_name">{{ item.keyName }}</div>
                <div class="value">{{ item.textValue }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 100px"/>
    <home-footer/>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="60%"
        :before-close="handleClose">
      <div class="info">
        <div class="carousel">
          <el-carousel height="450px">
            <el-carousel-item v-for="item in imageArr" :key="item">
              <el-image :src="returnImage(item)" style="width: 100%;height: 450px"/>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="detail">
          <div class="title">分类：</div>
          <el-row :gutter="10" class="category">
            <el-col :span="12">
              <div class="box" :class="{'check_box':isCheck===1}" @click="switchImg(1)">
                <div style="font-size: 18px;font-weight: bold">外观</div>
                <div style="font-size: 12px">({{ carInfo.picture ? carInfo.picture.split(",").length : 0 }}张)</div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="box" :class="{'check_box':isCheck===2}" @click="switchImg(2)">
                <div style="font-size: 18px;font-weight: bold">内饰</div>
                <div style="font-size: 12px">({{ carInfo.interior ? carInfo.interior.split(",").length : 0 }}张)</div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="box" :class="{'check_box':isCheck===3}" @click="switchImg(3)">
                <div style="font-size: 18px;font-weight: bold">细节</div>
                <div style="font-size: 12px">({{ carInfo.detail ? carInfo.detail.split(",").length : 0 }}张)</div>
              </div>
            </el-col>
          </el-row>
          <div class="car_name">
            {{ carInfo.brandType }}{{ new Date(carInfo.yearBuilt).getFullYear() }}款
          </div>
          <div style="color: #fff;font-size: 16px;margin-top: 20px">
            {{ new Date(carInfo.registeredDate).getFullYear() }} / {{ carInfo.mileage }}万公里
          </div>
          <div class="footer">
            <div style="font-size: 16px;font-weight: bold;color: #fff">二手车参考价</div>
            <div class="price">
              <span style="font-size: 16px;font-weight: bold">{{ (carInfo.price * 10000).toFixed(2) }}</span>
              <span style="font-size: 12px;padding-left: 5px;font-weight: bold">元</span>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <car-life-detail ref="carLifeDetail"/>
  </div>
</template>

<script>
import HomeFooter from "@/components/homeFooter.vue";
import {getTeamData, getCount, getVehicleData, getAdvantage, getBikerLife, baseUrl, getCarInfo} from "@/api/website";
import CarLifeDetail from "@/components/carLifeDetail.vue";

export default {
  name: "aboutMe",
  props: {},
  components: {CarLifeDetail, HomeFooter},
  data() {
    return {
      dialogVisible: false,
      teamInfo: {
        title: "",
        eTitle: "",
        description: "",
        background: ""
      },
      count: {
        staff: {
          count: 0,
          name: "员工"
        },
        vehiclesNum: {
          count: 0,
          name: "车辆"
        },
        clientNum: {
          count: 0,
          name: "优秀客户"
        },
        bikerLife: {
          count: 0,
          name: "车友生活"
        }
      },
      nowClickKey: "",//当前正在被点击的key
      show: false,
      carData: [],
      advantage: {
        background: "",
        list: []
      },
      lifeData: [],
      isCheck: 1,
      carInfo: {
        picture: "",
        interior: "",
        detail: ""
      }
    }
  },
  computed: {
    imageArr() {
      if (this.isCheck === 1) {
        return this.carInfo.picture ? this.carInfo.picture.split(",") : []
      }
      if (this.isCheck === 2) {
        return this.carInfo.interior ? this.carInfo.interior.split(",") : []
      }
      if (this.isCheck === 3) {
        return this.carInfo.detail ? this.carInfo.detail.split(",") : []
      }
      return []
    }
  },
  methods: {
    getData() {
      getTeamData().then(data => {
        data.data.forEach(item => {
          const {textValue, keyCode, imgValue} = item
          if (keyCode === "about_us_01_title") {
            this.teamInfo.title = textValue
            this.teamInfo.background = baseUrl + imgValue
          }
          if (keyCode === "about_us_01_content") {
            this.teamInfo.description = textValue
          }
          if (keyCode === "about_us_01_subhead") {
            this.teamInfo.eTitle = textValue
          }
        })
      }).catch(error => {
        console.log(error);
      })

      getCount().then(count => {
        for (const dataKey in count.data) {
          this.count[dataKey].count = count.data[dataKey]
        }
      }).catch(error => {
        console.log(error);
      })

      getVehicleData({pageNum: 1, pageSize: 6}).then(data => {
        this.carData = data.rows
      }).catch(error => {
        console.log(error);
      })

      getBikerLife({pageNum: 1, pageSize: 6}).then(data => {
        this.lifeData = data.rows
      }).catch(error => {
        console.log(error);
      })

      getAdvantage().then(data => {
        data.data.forEach((item, index) => {
          if (index === 0) {
            this.advantage.background = baseUrl + item.imgValue
          } else {
            this.advantage.list.push(item)
          }
        })
        console.log(this.advantage);
      }).catch(error => {
        console.log(error);
      })
    },
    lookMore(key) {
      if (this.nowClickKey === key) {
        this.nowClickKey = ''
        this.show = false;
      } else {
        this.nowClickKey = key;
        this.show = true
      }
    },
    returnImage(images) {
      const image = images.split(",")[0];
      return baseUrl + image
    },
    jump(path) {
      this.$router.push({
        path: path
      })
      window.scrollTo(0, 0)
    },
    lookInfo(car) {
      this.dialogVisible = true
      this.isCheck = 1
      getCarInfo(car.id).then(data => {
        this.carInfo = data.data
      }).catch(error => {
        console.log(error);
      })
    },
    handleClose() {
      this.dialogVisible = false
    },
    switchImg(type) {
      this.isCheck = type
    },
    lookInfoByLife(item) {
      this.$refs.carLifeDetail.handleOpen(item);
    },
  },
  mounted() {
    this.getData()
    const team = new IntersectionObserver((entries) => {
      for (const key of entries) {
        if (key.isIntersecting) {
          key.target.classList.add("team_move_right");
        }
      }
    }, {root: null, threshold: .3})
    team.observe(this.$refs.team)

    const aboutMe2 = new IntersectionObserver((entries) => {
      for (const key of entries) {
        if (key.isIntersecting) {
          key.target.classList.add("aboutMe_move_top");
        }
      }
    }, {root: null, threshold: .3})
    aboutMe2.observe(this.$refs.aboutMe2)

    const aboutMe3 = new IntersectionObserver((entries) => {
      for (const key of entries) {
        if (key.isIntersecting) {
          key.target.classList.add("aboutMe3_move_top");
        }
      }
    }, {root: null, threshold: .3})
    aboutMe3.observe(this.$refs.aboutMe3)


    const advantage = new IntersectionObserver((entries) => {
      for (const key of entries) {
        if (key.isIntersecting) {
          key.target.classList.add("advantage_move_top");
        }
      }
    }, {root: null, threshold: .3})
    advantage.observe(this.$refs.advantage)
  },
}
</script>

<style scoped lang="scss">
.aboutMe1 {
  height: 500px;
  background-image: url("@/assets/aboutMe1.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.team {
  height: 790px;
  width: 100%;
  position: relative;
  overflow-x: hidden;

  .img {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  .description {
    position: absolute;
    top: 43%;
    right: calc(100% - 430px - 200px);
    transform: translateY(-50%);
    width: 430px;
    opacity: 0;

    .title {
      font-size: 30px;
      color: rgb(92, 92, 92);
    }

    .eTitle {
      padding-top: 5px;
      font-size: 30px;
      color: rgb(169, 169, 169);
    }

    .line {
      width: 100px;
      height: 5px;
      background: #666;
      margin: 40px 0;
    }

    .content {
      color: #000;
      line-height: 28px;
      font-size: 14px;
    }
  }
}

.team_move_right {
  .description {
    animation: move_right 1s ease forwards;
  }
}

.aboutMe2 {
  height: 340px;
  background-image: url("@/assets/aboutMe2.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .count {
    height: 180px;
    width: 160px;
    color: #fff;
    text-align: center;
    position: relative;

    .pos {
      position: absolute;
      bottom: 0;
      right: 50%;
      transform: translateX(50%);
      z-index: 1;
      opacity: 0;

      .num {
        font-size: 48px;
        font-weight: 600;
      }

      .name {
        padding-top: 10px;
        font-size: 18px;
      }
    }

    .more {
      position: absolute;
      bottom: 0;
      right: 50%;
      transform: translateX(50%);
      z-index: 2;
      height: 50px;
      background: #2b2b2b;
      width: 100%;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      user-select: none;

      i {
        padding-right: 5px;
      }

      &:hover {
        background: #ffffff;
        cursor: pointer;
        color: #2b2b2b;
      }
    }
  }
}

.aboutMe_move_top {
  .pos {
    animation: move_top 1s ease forwards
  }
}

.collapse {
  min-height: 100px;
  box-sizing: border-box;
  padding: 20px;

  .box {
    cursor: pointer;
    display: flex;
    height: 120px;
    display: flex;
    align-items: center;


    .tag {
      position: absolute;
      top: 10px;
      right: 10px;
      border-radius: 6px;
      background: #444C55;
      z-index: 2;
      text-align: center;
      font-size: 14px;
      color: #ffffff;
      padding: 4px 16px;
    }

    .introduce {
      margin-left: 30px;
      flex: 1;
      border-bottom: 1px #ddd solid;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      box-sizing: border-box;
      padding: 10px 0;
    }
  }

  .bikerLife_box {
    cursor: pointer;
    width: 100%;
    height: 300px;
    background: #f5f5f5;
    border-radius: 12px;
    margin-top: 10px;
    position: relative;

    .tag {
      position: absolute;
      top: 10px;
      right: 10px;
      border-radius: 6px;
      background: #444C55;
      z-index: 2;
      text-align: center;
      font-size: 14px;
      color: #ffffff;
      padding: 4px 16px;
    }

    .image {
      width: 100%;
      height: 150px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    .des {
      height: 150px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 0 16px;
      box-sizing: border-box;
    }
  }
}

.aboutMe3 {
  height: 870px;
  display: flex;
  justify-content: center;
  padding-top: 200px;
  box-sizing: border-box;
  //align-items: center;

  .box {
    width: 1200px;

    .header {
      height: 64px;
      width: 100%;
      position: relative;

      .title {
        position: absolute;
        font-size: 30px;
        color: #000000;
        font-weight: bold;
        right: 50%;
        transform: translateX(50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        bottom: -64px;
        opacity: 0;
      }
    }

    .advantage {
      margin-top: 64px;
      width: 100%;
      height: 400px;
      position: relative;

      .advantage_image {
        position: absolute;
        width: 600px;
        height: 400px;
        bottom: -400px;
        left: 0;
        opacity: 0;
      }

      .advantage_content {
        position: absolute;
        width: 560px;
        height: 400px;
        right: 0;
        bottom: -400px;
        opacity: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .list {
          display: flex;
          align-items: center;
          height: 120px;

          .sub {
            font-size: 50px;
            color: #000;
          }

          .key_name {
            font-size: 18px;
            color: #000;
          }

          .value {
            font-size: 14px;
            line-height: 28px;
            padding-top: 8px;
          }
        }
      }
    }

    .advantage_move_top {
      .advantage_image, .advantage_content {
        animation: advantage_move_top 1s ease forwards;
      }
    }
  }
}

.aboutMe3_move_top {
  .title {
    animation: aboutMe3_move_top 1s ease forwards;
  }
}


@keyframes move_right {
  0% {
    right: -430px;
    opacity: 0;
  }
  100% {
    opacity: 1;
    right: calc(100% - 430px - 200px);
  }
}

@keyframes move_top {
  0% {
    bottom: 0;
    opacity: 0;
  }
  100% {
    opacity: 1;
    bottom: 80px;
  }
}

@keyframes aboutMe3_move_top {
  0% {
    bottom: -64px;
    opacity: 0;
  }
  100% {
    opacity: 1;
    bottom: 0px;
  }
}

@keyframes advantage_move_top {
  0% {
    bottom: -400px;
    opacity: 0;
  }
  100% {
    opacity: 1;
    bottom: 0px;
  }
}

:deep(.el-dialog__header) {
  display: none;
}

:deep(.el-dialog__body) {
  background: #3F3939;
}

.info {
  display: flex;

  .carousel {
    flex: 1;
    height: 450px;
  }

  .detail {
    width: 250px;
    margin: 0 20px;
    height: 450px;
    position: relative;

    .title {
      color: #ffffff;
      font-size: 16px;
    }

    .category {
      padding-top: 10px;

      .box {
        background: #666;
        width: 100%;
        height: 80px;
        margin-bottom: 10px;
        text-align: center;
        color: #ffffff;
        padding-top: 20px;
        box-sizing: border-box;
        cursor: pointer;
      }

      .check_box {
        background: #409EFF;
      }
    }

    .car_name {
      font-size: 18px;
      color: #ffffff;
      font-weight: bold;
      margin-top: 30px;
    }

    .footer {
      position: absolute;
      bottom: 0;
      left: 0;

      .price {
        width: 250px;
        background: #666;
        height: 40px;
        margin-top: 10px;
        line-height: 40px;
        padding-left: 10px;
        box-sizing: border-box;
        color: #409EFF;
      }
    }
  }
}
</style>
