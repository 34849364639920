import request from "@/common/request"

// export const baseUrl = "http://192.168.0.112:8515"
export const baseUrl = "https://car.jxyouyuntech.com"

/**
 * 获取团队信息
 * @param query
 * @returns {*}
 */
export function getTeamData() {
  return request({
    method: "get",
    url: "/officialWebsite/getConfig?keyCode=about_us_01"
  })
}

/**
 * 查询员工、车辆、优秀客户、车友生活数量
 * @returns {*}
 */
export function getCount() {
  return request({
    method: "get",
    url: "/officialWebsite/getCountData"
  })
}

/**
 * 查询所有车辆信息（分页）
 * @returns {*}
 */
export function getVehicleData(query) {
  return request({
    method: "get",
    url: "/officialWebsite/vehicle/list",
    params: query
  })
}

/**
 * 获取我们的优势
 * @param query
 * @returns {*}
 */
export function getAdvantage() {
  return request({
    method: "get",
    url: "/officialWebsite/getConfig?keyCode=about_us_02"
  })
}

/**
 * 车友生活（分页）
 * @param query
 * @returns {*}
 */
export function getBikerLife(query) {
  return request({
    method: "get",
    url: "/officialWebsite/bikerLife/list",
    params: query
  })
}

/**
 * 联系我们
 * @param query
 * @returns {*}
 */
export function getMeInfo() {
  return request({
    method: "get",
    url: "/officialWebsite/getConfig?keyCode=service"
  })
}

/**
 * 获取车辆详情信息
 * @param id
 * @returns {*}
 */
export function getCarInfo(carId) {
  return request({
    method: "get",
    url: "/officialWebsite/vehicle/getDetail?id=" + carId
  })
}
